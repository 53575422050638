export const pairs = {
    bang: {
      alt: 'bang',
      image: 'bang.png',
      song: 'bang_snippet.mp3',
      id: 1
    },
    bedroefd: {
      alt: 'bedroefd',
      image: 'bedroefd.png',
      song: 'bedroefd_snippet.mp3',
      id: 2
    },
    bezitterig: {
      alt: 'bezitterig',
      image: 'bezitterig.png',
      song: 'bezitterig_snippet.mp3',
      id: 3
    },
    blij: {
      alt: 'blij',
      image: 'blij.png',
      song: 'blij_snippet.mp3',
      id: 4
    },
    boos: {
      alt: 'boos',
      image: 'boos.png',
      song: 'boos_snippet.mp3',
      id: 5
    },
    eenzaam: {
      alt: 'eenzaam',
      image: 'eenzaam.png',
      song: 'eenzaam_snippet.mp3',
      id: 6
    },
    faalangst: {
      alt: 'faalangst',
      image: 'faalangst.png',
      song: 'faalangst_snippet.mp3',
      id: 7
    },
    fier: {
      alt: 'fier',
      image: 'fier.png',
      song: 'fier_snippet.mp3',
      id: 8
    },
    jaloezie: {
      alt: 'jaloezie',
      image: 'jaloezie.png',
      song: 'jaloezie_snippet.mp3',
      id: 9
    },
    lachen: {
      alt: 'lachen',
      image: 'lachen.png',
      song: 'lachen_snippet.mp3',
      id: 10
    },
    LDVD: {
      alt: 'LDVD',
      image: 'LDVD.png',
      song: 'LDVD_snippet.mp3',
      id: 11
    },
    leren: {
      alt: 'leren',
      image: 'leren.png',
      song: 'leren_snippet.mp3',
      id: 12
    },
    missen: {
      alt: 'missen',
      image: 'missen.png',
      song: 'missen_snippet.mp3',
      id: 13
    },
    neerkijken: {
      alt: 'neerkijken',
      image: 'neerkijken.png',
      song: 'neerkijken_snippet.mp3',
      id: 14
    },
    ongeduld: {
      alt: 'ongeduld',
      image: 'ongeduld.png',
      song: 'ongeduld_snippet.mp3',
      id: 15
    },
    pesten: {
      alt: 'pesten',
      image: 'pesten.png',
      song: 'pesten_snippet.mp3',
      id: 16
    },
    pijn: {
      alt: 'pijn',
      image: 'pijn.png',
      song: 'pijn_snippet.mp3',
      id: 17
    },
    rustig: {
      alt: 'rustig',
      image: 'rustig.png',
      song: 'rustig_snippet.mp3',
      id: 18
    },
    schrikken: {
      alt: 'schrikken',
      image: 'schrikken.png',
      song: 'schrikken_snippet.mp3',
      id: 19
    },
    verlangen: {
      alt: 'verlangen',
      image: 'verlangen.png',
      song: 'verlangen_snippet.mp3',
      id: 20
    },
    verliefd: {
      alt: 'verliefd',
      image: 'verliefd.png',
      song: 'verliefd_snippet.mp3',
      id: 21
    },
    verlies: {
      alt: 'verlies',
      image: 'verlies.png',
      song: 'verlies_snippet.mp3',
      id: 22
    },
    verwend: {
      alt: 'verwend',
      image: 'verwend.png',
      song: 'verwend_snippet.mp3',
      id: 23
    },
    verwondering: {
      alt: 'verwondering',
      image: 'verwondering.png',
      song: 'verwondering_snippet.mp3',
      id: 24
    },
    vriendschap: {
      alt: 'vriendschap',
      image: 'vriendschap.png',
      song: 'vriendschap_snippet.mp3',
      id: 25
    },
    walging: {
      alt: 'walging',
      image: 'walging.png',
      song: 'walging_snippet.mp3',
      id: 26
    },
    zelfvertrouwen: {
      alt: 'zelfvertrouwen',
      image: 'zelfvertrouwen.png',
      song: 'zelfvertrouwen_snippet.mp3',
      id: 27
    }
  }