import React, { Component } from 'react';

// import Card from '../../components/Cards/Card';

// data
import animals from './data';
// import './styles.css';

import { goBack, getInfo } from '../../scripts/helpers'

class AnimalsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sound: "",
            soundLink: "",
            correctSound: "",
            failSounds: "",
            animals: animals,
            activeAnimals: [],
            play: false,
            disabled: false,

            cardWidth: 25,
        };
        // Static variables
        this.animals = ['animal1', 'animal2', 'animal3', 'animal4'];
        this.info = null;
        // Create refs for retrieving DOM elements
        this.animal1 = React.createRef();
        this.animal2 = React.createRef();
        this.animal3 = React.createRef();
        this.animal4 = React.createRef();
        // Create game
        this.setAnimals();
        this.setSound();
    }

  audioExplainer = () => {
        if(localStorage.getItem('animals-explainer') == null) {
            localStorage.setItem('animals-explainer', true);
            this.info = getInfo('animals');
            setTimeout(() => {
                this.info.play();
                // show winokio head
                this.winokioExplainer(true);
            }, 1000);
            this.info.onended = () => {
                // remove winokio head
                this.winokioExplainer(false);
                this.state.sound.play();
            }
        }
    }

    winokioExplainer = (active) => {
        let winokio = document.querySelector('.winokio-explainer');
        if(active) {
            winokio.classList.add('winokio-explainer--active');
        } else {
            winokio.classList.remove('winokio-explainer--active')
        }
    }

    stopExplainer = () => {
        if(this.info) {
            // remove winokio head
            this.winokioExplainer(false);
            this.info.pause();
            this.info.currentTime = 0;
        }
    }

    randomNumber = (max) => {
        let randomNumber = Math.floor(Math.random() * max) + 1;
        return randomNumber - 1;
    }

    shuffle = (a) => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    setAnimals = async () => {
        let randomAnimals = await this.shuffle(this.state.animals);
        this.setState({
            animals: randomAnimals,
            activeAnimals: [
                randomAnimals[Object.keys(randomAnimals)[0]],
                randomAnimals[Object.keys(randomAnimals)[1]],
                randomAnimals[Object.keys(randomAnimals)[2]],
                randomAnimals[Object.keys(randomAnimals)[3]]
            ]
        });
        // Animate animal cards in
        this.animals.forEach(animal => {
            this.refs[animal].classList.remove('animated', 'zoomOut');
            this.refs[animal].classList.add('animated', 'zoomIn');
        });
        // Remove animated classes after 1 sec
        setTimeout(() => {
            this.animals.forEach(animal => {
                this.refs[animal].classList.remove('animated', 'zoomIn');
            });
        }, 1000);

    }

    setSound = async () => {
        let randomNumberAnimal = await this.randomNumber(4);
        let chosenSound = this.state.activeAnimals[randomNumberAnimal].sound;
        let chosenCorrectSound = this.state.activeAnimals[randomNumberAnimal].correctSound;
        this.setState({
            sound: new Audio(chosenSound),
            correctSound: new Audio(chosenCorrectSound),
            failSounds: this.state.activeAnimals[randomNumberAnimal].failSound,
            soundLink: chosenSound
        })
    }

    playSound = () => {
        // Stop explainer if already running
        this.stopExplainer();
        // show winokio head
        this.winokioExplainer(false);

        this.resetSound();
        this.state.sound.play();
    }

    resetSound = () => {
        this.state.sound.pause();
        // this.state.sound.currentTime = 0;
        this.setState({currentTime: 0});
    }

    checkResult = (el, animal) => {
        // Stop explainer if already running
        this.stopExplainer();
        // show winokio head
        this.winokioExplainer(false);
        // get this to use it in function
        let self = this;
        if(el === this.state.soundLink) {
            // Animate the correct answer
            this.refs[animal].classList.add('animated', 'rubberBand');
            // Animate out the wrong answers
            this.animals.forEach(a => {
                if(a !== animal) {
                    this.refs[a].classList.add('animated', 'zoomOut');
                }
            });
            this.state.correctSound.play();
            let correctSound = this.state.correctSound;
            correctSound.onended = () => {
                // Animate the correct answer
                this.refs[animal].classList.remove('animated', 'rubberBand');
                // Create new game
                this.setAnimals();
                this.setSound();
                self.setState({disabled: false})
            }
        } else {
            // Animate wrong answers
            this.refs[animal].classList.add('animated', 'shake');
            // Remove animated classes after 1 sec
            setTimeout(() => {
                this.refs[animal].classList.remove('animated', 'shake');
            }, 1000);
            let random = this.randomNumber(this.state.failSounds.length);
            let fail = new Audio(this.state.failSounds[random]);
            fail.play();
            fail.onended = () => {
                self.setState({disabled: false})
            }
        }
        this.setState({disabled: true})
    }

    startInfo = (game) => {
        if(this.state.disabled == false) {
          // Stop explainer if already running
          this.stopExplainer();
          // show winokio head
          this.winokioExplainer(true);
            // Start info
            this.info = getInfo(game);
            this.info.play();
            // this.setState({disabled: true});
            this.info.onended = () => {
              // show winokio head
              this.winokioExplainer(false);
              // this.setState({disabled: false});
            }
        }
    }

    startInfo = (game) => {
        if(this.state.disabled == false) {
            // Stop explainer if already running
            this.stopExplainer();
            // show winokio head
            this.winokioExplainer(true);
            // Start info
            this.info = getInfo(game);
            this.info.play();
            // this.setState({disabled: true});
            this.info.onended = () => {
                // show winokio head
                this.winokioExplainer(false);
                // this.setState({disabled: false});
            }
        }
    }

    componentWillUnmount = () => {
        if(this.info) {
          this.info.pause();
          this.state.sound.pause();
        }
    }

    componentDidMount = () => {
        setTimeout(() => {
            // Start with explainer if not already listened\
            this.audioExplainer();
            if(!this.info) {
                this.state.sound.play();
            }
        }, 50);
    }

    render() {
        return (
            <div>
                <div className="animals-game">
                    <div className="winokio-explainer">
                        <img src="assets/img/islands/kapitein-hoofd.png" />
                    </div>
                    <div className="back-button" onClick={goBack}></div>
                    <div className="info-button" onClick={() => this.startInfo('animals')}></div>
                    <div className="game-container">
                        <button  
                            disabled={this.state.disabled}
                            ref="animal1"
                            className="animals-card animal1" 
                            style={{backgroundImage: "url(" + this.state.animals[0].image + ")", width: this.state.cardWidth + "%" }}
                            onClick={() => this.checkResult(this.state.animals[0].sound, 'animal1')}
                            ></button>

                        <button 
                            disabled={this.state.disabled}
                            ref="animal2"
                            className="animals-card animal2" 
                            style={{backgroundImage: "url(" + this.state.animals[1].image + ")", width: this.state.cardWidth + "%" }}
                            onClick={() => this.checkResult(this.state.animals[1].sound, 'animal2')}
                        ></button>
                        <button 
                            disabled={this.state.disabled}
                            ref="animal3"
                            className="animals-card animal3" 
                            style={{backgroundImage: "url(" + this.state.animals[2].image + ")", width: this.state.cardWidth + "%" }}
                            onClick={() => this.checkResult(this.state.animals[2].sound, 'animal3')}
                        ></button>
                        
                        <button 
                            disabled={this.state.disabled}
                            ref="animal4"
                            className="animals-card animal4" 
                            style={{backgroundImage: "url(" + this.state.animals[3].image + ")", width: this.state.cardWidth + "%" }}
                            onClick={() => this.checkResult(this.state.animals[3].sound, 'animal4')}
                        ></button>
                    </div>
                    {/* Game Container */}
                    <div className="background background--green"></div>
                    {/* Background */}
                </div>
                {/* Instrument Game */}
                <div className="main-btn-container">
                    <button disabled={this.state.disabled} className="main-btn audio-btn" href="#" onClick={this.playSound}>
                        <img src="/assets/img/audio.svg" alt="Audio" />
                    </button>
                </div>
            </div>
        );
    }
}

export default AnimalsView;
