import React, { Component } from 'react';
// import './scss/_memory.scss';
import { shuffleArray } from '../../scripts/helpers'
import { backgrounds } from '../../containers/MemoryView/variables/backgrounds'

const grades = ['90', '180', '270', '360']

export default class MemoryCard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            background: shuffleArray(backgrounds)[0],
            grades: shuffleArray(grades)[0],
        }
    }

    render() {

        const image = `./assets/img/memory/${this.props.pair.image}`
        const alt = this.props.pair.alt
        const id = parseInt(this.props.pair.id)
        const floatId = this.props.pair.id

        return (
            <div className="memory-card animated fadeIn" style={{width: this.props.cardWidth + "%"}} onClick={this.props.onClick} data-id={id} data-float-id={floatId}>

                <div className="front">

                    <img className="front__image" src={image} alt={alt}></img>

                </div>

                <div className="back">

                    <img className="back__image" src={this.state.background} alt="Kaart achterkant" style={{ transform: `rotate(${this.state.grades}deg)` }}></img>

                </div>

            </div>
        );
    }
}