/**
 * Used for shuffling arrays into a random order
 * @param Array sourceArray 
 */
export function shuffleArray(sourceArray) {
	for (let i = 0; i < sourceArray.length - 1; i++) {
		let j = i + Math.floor(Math.random() * (sourceArray.length - i));

		let temp = sourceArray[j];
		sourceArray[j] = sourceArray[i];
		sourceArray[i] = temp;
	}
	return sourceArray;
}

export function goBack() {
	window.history.back();
}

export const getInfo = (game) => {
	let data = {
		animals: '/assets/sounds/info/dierenspel.wav',
		veggies: '/assets/sounds/info/groentenspel.wav',
		memory: '/assets/sounds/info/emotiespel.wav',
		seasons: '/assets/sounds/info/seizoensspel.wav',
		music: '/assets/sounds/info/singalong.wav',
	}
	let info = new Audio(data[game]);
	return info;
}

export const randomNumber = (max) => {
	let randomNumber = Math.floor(Math.random() * max);
	return randomNumber;
}