import React, { Component, useState } from 'react';
// import { routes } from '../App/App'
import { Link } from "react-router-dom";
// import Hammer from 'react-hammerjs';
// import Islands from '../../components/Islands/Islands';
import Swiper from 'react-id-swiper/lib/ReactIdSwiper.full';
import './styles.scss';

class StartView extends Component {

  constructor(props) {
    super(props)
		this.state = {
      hor: 0,
      move: 0,
      audio: '',
      activeIsland: '',
      initialPosition: 0,
      menuCollapsed: false,
      movement: 1,
      lastPosition: null,
    }

    // Static variables
    this.islands = {
      veggies: './assets/sounds/islands/groente-eiland.wav',
      seasons: './assets/sounds/islands/seizoenseiland.wav',
      memory: './assets/sounds/islands/emotie-eiland.wav',
      animals: './assets/sounds/islands/diereneiland.wav',
      music: './assets/sounds/islands/muziekeiland.wav',
    };

    // Create refs for retrieving DOM elements
    this.veggies = React.createRef();
    this.seasons = React.createRef();
    this.memory = React.createRef();
    this.animals = React.createRef();
    this.music = React.createRef();
  }

  onSlideChange = () => {
    setTimeout(() => {
      let slides = document.querySelectorAll('.swiper-slide');
      slides.forEach(slide => {
        if(slide.classList.contains('swiper-slide-active') && this.state.activeIsland != slide.id) {
          // Set active island to local storage
          localStorage.setItem('island', slide.id);
          this.setState({
            activeIsland: slide.id,
            audio: new Audio(this.islands[slide.id])
          });
          this.state.audio.play();
          let winokioAudioIcon = document.querySelector('.winokio-audio');
          winokioAudioIcon.classList.add('winokio-audio--active');
          this.state.audio.onended = () => {
            winokioAudioIcon.classList.remove('winokio-audio--active');
          }
        }
      });
    }, 100);
    
    // ! Old code
    // setTimeout(() => {
    //   const keys = Object.keys(this.refs)
    //   keys.forEach(key => {
    //     if(this.refs[key].classList.contains('swiper-slide-duplicate-active')) {
    //       let audio = new Audio(this.islands[key]);
    //       audio.play();
    //     } else if(this.refs[key].classList.contains('swiper-slide-active')) {
    //       let audio = new Audio(this.islands[key]);
    //       audio.play();
    //     } 
    //   });
    // }, 100);
  }

  onTouchMove = (e) => {
    // console.log(e);
    let islandInitialPosition = this.state.initialPosition;
    let rotation = (islandInitialPosition - e.x) / 80;
    let wrapper = document.querySelector('.swiper-slide-active a');
    // this.refs.veggies.style.transition = "transform 0.1s"
    if(e.x < this.state.lastPosition && e.x < islandInitialPosition) {
      this.setState({movement: (islandInitialPosition - e.x) / 20});
    } else {
      if(e.x > islandInitialPosition) {
        this.setState({movement: -(islandInitialPosition - e.x) / 20});
      } else {
        if(e.x < this.state.lastPosition) {
          this.setState({movement: -(islandInitialPosition - e.x) / 20});
        } else {
          this.setState({movement: (islandInitialPosition - e.x) / 20});
        }
      }
    }
    wrapper.style.transform = "rotate("+ -rotation +"deg) translateY("+ this.state.movement * 3 +"px)";
    this.setState({lastPosition: e.x});
  }

  onTouchStart = (e) => {
    this.setState({initialPosition: e.x});
  }

  onTouchEnd = (e) => {
    this.setState({
      movement: 1,
      lastPosition: null,
    })
    let wrapper = document.querySelector('.swiper-slide-active a');
    wrapper.style.transition = "0.25s all"
    wrapper.style.transform = "rotate(0deg) translateY(0px)";
    setTimeout(() => {
      wrapper.style.setProperty('transition', 'none', 'important');
    }, 500);
  }

  onTransitionStart = () => {
    let active = document.querySelector('.swiper-slide-active a');
    let prev = document.querySelector('.swiper-slide-prev a');
    let next = document.querySelector('.swiper-slide-next a');
    prev.style.transform = "rotate(-8deg) translateY(140px)";
    next.style.transform = "rotate(8deg) translateY(140px)";
    active.style.transition = "0.25s all";
    active.style.transform = "rotate(0deg) translateY(0px)";
  }

  onTransitionEnd = () => {
    let wrapper = document.querySelector('.swiper-slide-active a');
    setTimeout(() => {
      wrapper.style.setProperty('transition', 'none', 'important');
    }, 500);
  }

  toggleMenu = () => {
    if (this.state.menuCollapsed == false) {
      document.querySelector('.menu-container').classList.add('menu-container--collapsed');
      document.querySelector('.burger').style.transform = 'translateY(170px)';
      this.setState({menuCollapsed: true});
    } else {
      document.querySelector('.menu-container').classList.remove('menu-container--collapsed');
      document.querySelector('.burger').style.transform = 'translateY(0px)';
      this.setState({menuCollapsed: false});
    }
  }

  swipeTo = (slide) => {
      this.updateSwiper.slideTo(slide);
  }

  handleIslandClick = (island) => {
    // Set active island to local storage
    localStorage.setItem('island', island);
  }

  componentDidMount = () => {
    // window.addEventListener("resize", this.stick);
    setTimeout(() => {
      // this.swipeTo(2);
      // console.log(swiper)
    }, 1000);
  }



  render() {

    const params = {
      effect: 'slide',
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      speed: 500,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        // el: '.swiper-pagination',
      },
      on: {
        slideChange:this.onSlideChange,
        touchMove:this.onTouchMove,
        touchStart:this.onTouchStart,
        touchEnd:this.onTouchEnd,
        transitionStart:this.onTransitionStart,
        transitionEnd:this.onTransitionEnd
      },
      activeSlideKey: localStorage.getItem('island') ? localStorage.getItem('island') : 'veggies',
    };
    
    return (
      <div className="startView">
        <button onClick={() => this.toggleMenu()} className="burger">
          <img src="/assets/img/burger.svg" />
        </button>
        <div className="menu-container">
          <div className="menu-wrapper">
            <Link onClick={() => this.handleIslandClick('veggies')} className="menu-item" to={'/veggies'}> 
              <img className="menu-item-img" src='./assets/img/islands/groenten-eiland-sm.svg' alt="Groentenspel"/>
            </Link>
            <Link onClick={() => this.handleIslandClick('seasons')} className="menu-item" to={'/seizoenen'}> 
              <img className="menu-item-img" src='./assets/img/islands/seizoenen-eiland-sm.svg' alt="Seizoenenspel"/>
            </Link>
            <Link onClick={() => this.handleIslandClick('memory')} className="menu-item" to={'/memory'}> 
              <img className="menu-item-img" src='./assets/img/islands/emotie-eiland-sm.svg' alt="Memory"/>
            </Link>
            <Link onClick={() => this.handleIslandClick('animals')} className="menu-item" to={'/dieren'}> 
              <img className="menu-item-img" src='./assets/img/islands/dieren-eiland-sm.svg' alt="Dierenspel"/>
            </Link>
            <Link onClick={() => this.handleIslandClick('music')} className="menu-item" to={'/singalong'}> 
              <img className="menu-item-img" src='./assets/img/islands/instrumenten-eiland-sm.svg' alt="Singalong"/>
            </Link>
          </div>
        </div>
        <div className="navigation-container">
          <Swiper {...params} className="swiper-container">
            <div key="veggies" ref="veggies" id="veggies">
              <Link className="" to={'/veggies'}> 
                <img className="image" src='./assets/img/islands/groenten-eiland.svg' alt="Groentenspel"/>
              </Link>
            </div>
            <div key="seasons" ref="seasons" id="seasons">
              <Link className="" to={'/seizoenen'}> 
                <img className="image" src='./assets/img/islands/seizoenen-eiland.svg' alt="Seizoenenspel"/>
              </Link>
            </div>
            <div key="memory" ref="memory" id="memory">
              <Link className="" to={'/memory'}> 
                <img className="image" src='./assets/img/islands/emotie-eiland.svg' alt="Memory"/>
              </Link>
            </div>
            <div key="animals" ref="animals" id="animals">
              <Link className="" to={'/dieren'}> 
                <img className="image" src='./assets/img/islands/dieren-eiland.svg' alt="Dierenspel"/>
              </Link>
            </div>
            <div key="music" ref="music" id="music">
              <Link className="" to={'/singalong'}> 
                <img className="image" src='./assets/img/islands/instrumenten-eiland.svg' alt="Singalong"/>
              </Link>
            </div>
          </Swiper>
        </div>
        <div className="water"></div>
        <div className="winokio-boot">
          <img className="winokio-audio" src="/assets/img/islands/chat.svg" alt="Winokio praat" />
          <img className="winokio" src="/assets/img/islands/kapiteinboot@2x.png" alt="de boot van kapitein winokio"/>
        </div>
      </div>
    
   
    );
  }
}

export default StartView;