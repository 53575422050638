import React, { Component } from 'react'
import SongCard from '../../components/Cards/SongCard'
import { songs } from './variables/songs'
import { goBack, getInfo } from '../../scripts/helpers'
import './styles.scss'

class SingalongView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disabled: false
        }

        this.info = null;
    }


  audioExplainer = () => {
        if(localStorage.getItem('music-explainer') == null) {
            localStorage.setItem('music-explainer', true);
            this.info = getInfo('music');
            setTimeout(() => {
                this.info.play();
                // show winokio head
                this.winokioExplainer(true);
            }, 1000);
            this.info.onended = () => {
                // remove winokio head
                this.winokioExplainer(false);
            }
        }
    }

    winokioExplainer = (active) => {
        let winokio = document.querySelector('.winokio-explainer');
        if(active) {
            winokio.classList.add('winokio-explainer--active');
        } else {
            winokio.classList.remove('winokio-explainer--active')
        }
    }

    stopExplainer = () => {
        if(this.info) {
            // remove winokio head
            this.winokioExplainer(false);
            this.info.pause();
            this.info.currentTime = 0;
        }
    }

    buildSongs() {
        // Stop explainer if already running
        this.stopExplainer();

        let links = []

        // Loop over the available songs
        for (var key in songs) {
            // Check if the song exists
            if (songs.hasOwnProperty(key)) {
                // Add the component to the links list
                links.push(<SongCard song={songs[key]} key={key} />)
            }   
        }

        return links
    }

    startInfo = (game) => {
        if(this.state.disabled == false) {
            // Stop explainer if already running
            this.stopExplainer();
            // show winokio head
            this.winokioExplainer(true);
            // Start info
            this.info = getInfo(game);
            this.info.play();
            // this.setState({disabled: true});
            this.info.onended = () => {
                // show winokio head
                this.winokioExplainer(false);
                // this.setState({disabled: false});
            }
        }
    }

    componentWillUnmount = () => {
        if(this.info) {
            this.info.pause();
        }
    }

    componentDidMount = () => {
        this.audioExplainer();
    }

    render() {
        return (
            <div className="singalongView singalongOverview">
                <div className="winokio-explainer">
                    <img src="assets/img/islands/kapitein-hoofd.png" />
                </div>
                <div className="back-button" onClick={goBack}></div>
                <div className="info-button" onClick={() => this.startInfo('music')}></div>
                <div className="singalongOverview">
                    {this.buildSongs()}
                </div>
                <div className="background background--yellow"></div>
            </div>
        )  
    }
}

export default SingalongView;