let data = [
    {
        animal: 'beer',
        image: './assets/img/animals/beer.svg',
        sound: './assets/sounds/animals/beer.mp3',
        correctSound: './assets/sounds/animals/beer_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'biggetje',
        image: './assets/img/animals/biggetje.svg',
        sound: './assets/sounds/animals/biggetje.mp3',
        correctSound: './assets/sounds/animals/biggetje_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'eend',
        image: './assets/img/animals/eend.svg',
        sound: './assets/sounds/animals/eend.mp3',
        correctSound: './assets/sounds/animals/eend_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'egel',
        image: './assets/img/animals/egel.svg',
        sound: './assets/sounds/animals/egel.mp3',
        correctSound: './assets/sounds/animals/egel_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'ezel',
        image: './assets/img/animals/ezel.svg',
        sound: './assets/sounds/animals/ezel.mp3',
        correctSound: './assets/sounds/animals/ezel_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'kat',
        image: './assets/img/animals/kater.svg',
        sound: './assets/sounds/animals/kat.mp3',
        correctSound: './assets/sounds/animals/kat_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'kip',
        image: './assets/img/animals/kip.svg',
        sound: './assets/sounds/animals/kip.mp3',
        correctSound: './assets/sounds/animals/kip_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'koe',
        image: './assets/img/animals/koe.svg',
        sound: './assets/sounds/animals/koe.mp3',
        correctSound: './assets/sounds/animals/koe_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'konijn',
        image: './assets/img/animals/konijn.svg',
        sound: './assets/sounds/animals/konijn.mp3',
        correctSound: './assets/sounds/animals/konijn_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'lam',
        image: './assets/img/animals/lam.svg',
        sound: './assets/sounds/animals/lam.mp3',
        correctSound: './assets/sounds/animals/lam_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    // {
    //     animal: 'meeuw&leeuwerik',
    //     image: './assets/img/animals/meeuw&leeuwerik.svg',
    //     sound: './assets/sounds/animals/meeuw&leeuwerik.mp3',
    //     correctSound: './assets/sounds/animals/meeu_textw&leeuwerik.mp3'
    // },
    // {
    //     animal: 'mol',
    //     image: './assets/img/animals/mol.svg',
    //     sound: './assets/sounds/animals/mol.mp3',
    //     correctSound: './assets/sounds/animals/mol_text.mp3'
    // },
    {
        animal: 'muis',
        image: './assets/img/animals/muis.svg',
        sound: './assets/sounds/animals/muis.mp3',
        correctSound: './assets/sounds/animals/muis_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'ooievaar',
        image: './assets/img/animals/ooievaar.svg',
        sound: './assets/sounds/animals/ooievaar.mp3',
        correctSound: './assets/sounds/animals/ooievaar_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'paard',
        image: './assets/img/animals/paard.svg',
        sound: './assets/sounds/animals/paard.mp3',
        correctSound: './assets/sounds/animals/paard_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'poedel',
        image: './assets/img/animals/poedel.svg',
        sound: './assets/sounds/animals/poedel.mp3',
        correctSound: './assets/sounds/animals/poedel_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'spin',
        image: './assets/img/animals/spin.svg',
        sound: './assets/sounds/animals/spin.mp3',
        correctSound: './assets/sounds/animals/spin_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'uil',
        image: './assets/img/animals/uil.svg',
        sound: './assets/sounds/animals/uil.mp3',
        correctSound: './assets/sounds/animals/uil_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'vleermuis',
        image: './assets/img/animals/vleermuis.svg',
        sound: './assets/sounds/animals/vleermuis.mp3',
        correctSound: './assets/sounds/animals/vleermuis_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        animal: 'vogels',
        image: './assets/img/animals/vogels.svg',
        sound: './assets/sounds/animals/vogels.mp3',
        correctSound: './assets/sounds/animals/vogels_text.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    // {
    //     animal: 'vos',
    //     image: './assets/img/animals/vos.svg',
    //     sound: './assets/sounds/animals/vos.mp3',
    //     correctSound: './assets/sounds/animals/vos_text.mp3'
    // }
];

export default data;