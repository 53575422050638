// React components
import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './styles.css';

// Views components
import StartView from '../StartView/StartView';
import MemoryView from '../MemoryView/MemoryView';
import SeasonsView from '../SeasonsView/SeasonsView';
import VeggiesView from '../VeggiesView/VeggiesView';
import SingalongView from '../SingalongView/SingalongView';
import SongView from '../SingalongView/SongView';
// Extra components
// import logo from './logo.svg';
import './styles.css';
import AnimalsView from '../AnimalsView/AnimalsView';

// Routes
export const routes = [
  {
    path: '/',
    component: StartView,
  },
  {
    path: '/memory',
    component: MemoryView,
  },
  {
    path: '/dieren',
    component: AnimalsView,
  },
  {
    path: '/seizoenen',
    component: SeasonsView,
  },
  {
    path: '/veggies',
    component: VeggiesView,
  },
  {
    path: '/singalong',
    component: SingalongView,
  },
  {
    path: '/singalong/:song',
    component: SongView,
  }
];

class App extends Component {

  render() {

    // Source: https://alligator.io/react/react-router-map-to-routes/
    const routeComponents = routes.map( ({ path, component }, key ) => 
      <Route exact path={path} component={component} key={key} />
    )

    return (
      <div className="App">

        <Router>
          <div className="fullScreen">
            {routeComponents}
          </div>
        </Router>

        <div className="notification">
          <div className="notification__captain"></div>
          Voor dit spelletje moet je je toestel draaien!
        </div>
        
      </div>
    );
  }
}

export default App;
