let veggies = [
    {
        name: 'artisjok',
        image: './assets/img/veggies/artisjok.svg',
        song: './assets/sounds/veggies/artisjok_snippet.mp3',
        word: './assets/sounds/veggies/word/artisjok.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'augurk',
        image: './assets/img/veggies/augurk.svg',
        song: './assets/sounds/veggies/augurk_snippet.mp3',
        word: './assets/sounds/veggies/word/augurk.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'avocado',
        image: './assets/img/veggies/avocado.svg',
        song: './assets/sounds/veggies/avocado_snippet.mp3',
        word: './assets/sounds/veggies/word/avocado.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'bamboescheut',
        image: './assets/img/veggies/bamboescheut.svg',
        song: './assets/sounds/veggies/bamboescheut_snippet.mp3',
        word: './assets/sounds/veggies/word/bamboescheut.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'bonen',
        image: './assets/img/veggies/bonen.svg',
        song: './assets/sounds/veggies/bonen_snippet.mp3',
        word: './assets/sounds/veggies/word/bonen.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'brandnetel',
        image: './assets/img/veggies/brandnetel.svg',
        song: './assets/sounds/veggies/brandnetel_snippet.mp3',
        word: './assets/sounds/veggies/word/brandnetel.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'broccoli',
        image: './assets/img/veggies/broccoli.svg',
        song: './assets/sounds/veggies/broccoli_snippet.mp3',
        word: './assets/sounds/veggies/word/broccoli.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'courgette',
        image: './assets/img/veggies/courgette.svg',
        song: './assets/sounds/veggies/courgette_snippet.mp3',
        word: './assets/sounds/veggies/word/courgette.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'ijsbergsla',
        image: './assets/img/veggies/ijsbergsla.svg',
        song: './assets/sounds/veggies/ijsbergsla_snippet.mp3',
        word: './assets/sounds/veggies/word/ijsbergsla.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'kikkererwt',
        image: './assets/img/veggies/kikkererwt.svg',
        song: './assets/sounds/veggies/kikkererwt_snippet.mp3',
        word: './assets/sounds/veggies/word/kikkererwt.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'knoflook',
        image: './assets/img/veggies/knoflook.svg',
        song: './assets/sounds/veggies/knoflook_snippet.mp3',
        word: './assets/sounds/veggies/word/knoflook.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'knolraap',
        image: './assets/img/veggies/knolraap.svg',
        song: './assets/sounds/veggies/knolraap_snippet.mp3',
        word: './assets/sounds/veggies/word/knolraap.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'komkommmer',
        image: './assets/img/veggies/komkommer.svg',
        song: './assets/sounds/veggies/komkommer_snippet.mp3',
        word: './assets/sounds/veggies/word/komkommer.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'mais',
        image: './assets/img/veggies/mais.svg',
        song: './assets/sounds/veggies/mais_snippet.mp3',
        word: './assets/sounds/veggies/word/mais.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'pompoen',
        image: './assets/img/veggies/pompoen.svg',
        song: './assets/sounds/veggies/pompoen_snippet.mp3',
        word: './assets/sounds/veggies/word/pompoen.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'prei',
        image: './assets/img/veggies/prei.svg',
        song: './assets/sounds/veggies/prei_snippet.mp3',
        word: './assets/sounds/veggies/word/prei.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'rabarber',
        image: './assets/img/veggies/rabarber.svg',
        song: './assets/sounds/veggies/rabarber_snippet.mp3',
        word: './assets/sounds/veggies/word/rabarber.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'rodekool',
        image: './assets/img/veggies/rodekool.svg',
        song: './assets/sounds/veggies/rodekool_snippet.mp3',
        word: './assets/sounds/veggies/word/rodekool.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'rucola',
        image: './assets/img/veggies/rucola.svg',
        song: './assets/sounds/veggies/rucola_snippet.mp3',
        word: './assets/sounds/veggies/word/rucola.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'schorseneer',
        image: './assets/img/veggies/schorseneer.svg',
        song: './assets/sounds/veggies/schorseneer_snippet.mp3',
        word: './assets/sounds/veggies/word/schorseneer.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'selder',
        image: './assets/img/veggies/selder.svg',
        song: './assets/sounds/veggies/selder_snippet.mp3',
        word: './assets/sounds/veggies/word/selder.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'spinazie',
        image: './assets/img/veggies/spinazie.svg',
        song: './assets/sounds/veggies/spinazie_snippet.mp3',
        word: './assets/sounds/veggies/word/spinazie.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'spruitjes',
        image: './assets/img/veggies/spruitjes.svg',
        song: './assets/sounds/veggies/spruitjes_snippet.mp3',
        word: './assets/sounds/veggies/word/spruitjes.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'tomaat',
        image: './assets/img/veggies/tomaat.svg',
        song: './assets/sounds/veggies/tomaat_snippet.mp3',
        word: './assets/sounds/veggies/word/tomaat.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'ui',
        image: './assets/img/veggies/ui.svg',
        song: './assets/sounds/veggies/ui_snippet.mp3',
        word: './assets/sounds/veggies/word/ui.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'venkel',
        image: './assets/img/veggies/venkel.svg',
        song: './assets/sounds/veggies/venkel_snippet.mp3',
        word: './assets/sounds/veggies/word/venkel.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'witloof',
        image: './assets/img/veggies/witloof.svg',
        song: './assets/sounds/veggies/witloof_snippet.mp3',
        word: './assets/sounds/veggies/word/witloof.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'wortel',
        image: './assets/img/veggies/wortel.svg',
        song: './assets/sounds/veggies/wortel_snippet.mp3',
        word: './assets/sounds/veggies/word/wortel.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
    {
        name: 'zoete-patat',
        image: './assets/img/veggies/zoete-patat.svg',
        song: './assets/sounds/veggies/zoete-patat_snippet.mp3',
        word: './assets/sounds/veggies/word/zoete-patat.mp3',
        failSound: [
            './assets/sounds/fail-sounds/fout_01.mp3',
            './assets/sounds/fail-sounds/fout_02.mp3',
            './assets/sounds/fail-sounds/fout_03.mp3',
            './assets/sounds/fail-sounds/fout_04.mp3',
            './assets/sounds/fail-sounds/fout_05.mp3',
            './assets/sounds/fail-sounds/fout_06.mp3',
            './assets/sounds/fail-sounds/fout_07.mp3',
            './assets/sounds/fail-sounds/fout_08.mp3',
        ]
    },
];

export default veggies;