import React, { Component } from 'react';
import MemoryCard from '../../components/Cards/MemoryCard'
import { shuffleArray, goBack, getInfo } from '../../scripts/helpers'
import queryString from 'query-string'
import { pairs } from './variables/pairs'

let initialPairs = 2
const maxPairs = 4

class MemoryView extends Component {

  constructor(props) {
    super(props)

    // parse all GET parameters
    let query = queryString.parse(this.props.location.search)
    let initial = parseInt(query.initial)

    // Setup the initial pairs or default to the let var
    initialPairs = !isNaN(initial) ? (initial <= maxPairs ? initial : maxPairs || initialPairs) : initialPairs

    this.state = {
      pairsToBuild: initialPairs,
      pairsToPlay: [],
      pairClicked: 0,
      singleClicked: 0,
      cards: [],
      disabled: false,
      cardWidth: 150,
      emotion: '',

      // %
      width: 50,
    }

    // Static variable
    this.info = null;
    this.correctAudio = null;

  }

  audioExplainer = () => {
      if(localStorage.getItem('memory-explainer') == null) {
          localStorage.setItem('memory-explainer', true);
          this.info = getInfo('memory');
          setTimeout(() => {
              this.info.play();
              // show winokio head
              this.winokioExplainer(true);
          }, 1000);
          this.info.onended = () => {
              // remove winokio head
              this.winokioExplainer(false);
              // this.playWord();
          }
      }
  }

  winokioExplainer = (active) => {
      let winokio = document.querySelector('.winokio-explainer');
      if(active) {
          winokio.classList.add('winokio-explainer--active');
      } else {
          winokio.classList.remove('winokio-explainer--active')
      }
  }

  stopExplainer = () => {
      if(this.info) {
          // remove winokio head
          this.winokioExplainer(false);
          this.info.pause();
          this.info.currentTime = 0;
      }
  }

  // Used for scaling the game
  responsive = () => {

    if(window.innerWidth >= 760) {
      this.setState({cardWidth: 250})
    } else {
      this.setState({ cardWidth: 150 })
    }

  }

  // Used for selecting pairs used in the game
  selectPairs() {

    // Prepare an empty random pairs object
    let randomPairs = []
    // Create a copy of pairs that doesn't update the state
    let originalPairs = Object.assign({}, pairs)

    // Create as many pairs as required
    for (let i = 0; i < this.state.pairsToBuild; i++) {

      let keys = Object.keys(originalPairs)
      let random = keys[keys.length * Math.random() << 0]

      // Assign the pair to the list of random pairs, ensuring the original isn't edited
      randomPairs.push(Object.assign({}, originalPairs[random]))

      // Create an unique id for the second iteration of the random pair member and push it
      let second = Object.assign({}, originalPairs[random])
      second.id += .5
      randomPairs.push(Object.assign({}, second))

      // Removes the pair from the list of original pairs
      delete originalPairs[random]

    }

    // Update the state with pairs to play
    this.setState({ pairsToPlay: shuffleArray(randomPairs) })

  }

  // Function to start correct song
  startCorrectSong(id) {
    this.state.pairsToPlay.forEach(pair => {
      if(pair.id == id) {
        // Set audio
        this.correctAudio = new Audio('assets/sounds/emotions/'+pair.song);
        this.correctAudio.play();
        // Disable buttons during the audio and set emotion text
        this.setState({
          emotion: pair.alt,
          disabled: true
        });
        this.correctAudio.onended = () => {
          // Enable buttons after the audio and remove emotion text
          this.setState({
            emotion: '',
            disabled: false
          });
        }
      }
    });
  }

  // Function to get correct cards
  correctCards(id) {
    let correct = document.querySelectorAll(`.memory-card[data-id='${id}']`);

    for (let i = 0; i < correct.length; i++) {
      correct[i].classList.add('memory-card--correct')
    }

  }

  // Used for checking if the level was completed
  checkIfCompleted() {
    let cards = document.querySelectorAll(`.memory-card`).length
    let correct = document.querySelectorAll(`.memory-card--correct`).length

    return (cards === correct)
  }

  // Function to reset cards
  resetCards = () => {
    let rotated = document.querySelectorAll('.memory-card--rotated:not(.memory-card--correct)');

    // Disable clicking
    this.setState({ disabled: true })

    // Delay by one second
    setTimeout(() => {

      for (let i = 0; i < rotated.length; i++) {
        rotated[i].classList.remove('memory-card--rotated')
        rotated[i].classList.remove('pulse')
        rotated[i].removeEventListener("transitionend", this.resetCards)
      }

      return this.setState({
        pairClicked: 0,
        singleClicked: 0,
        disabled: false,
      })

    }, 1000)

  }

  handleClick = async (e) => {
    // Stop explainer if already running
    this.stopExplainer();
    // show winokio head
    this.winokioExplainer(false);

    if (!this.state.disabled) {

      let card = e.currentTarget
      let id = parseInt(card.dataset.id)
      let floatId = card.dataset.floatId

      // Check if a pair has yet been clicked
      if (this.state.pairClicked > 0) {

        // Pulse the card
        card.classList.remove('fadeIn')
        card.classList.add('pulse')

        // Rotate the card
        card.classList.add('memory-card--rotated')

        // Check if another pair was clicked
        if (this.state.pairClicked !== id || this.state.singleClicked === floatId) {

          // Wait for the flip ending
          card.addEventListener("transitionend", this.resetCards)

          // Correct card was clicked
        } else {

          // Start song on correct pair
          this.startCorrectSong(id);
          // Mark the pair as correct
          this.correctCards(id)

          // Delay restart of new game with a second to prevent flashing
          setTimeout(() => {

            // Check if the level was completed
            if (this.checkIfCompleted()) {
              // Start new game on audio end
              this.correctAudio.onended = () => {
                // Enable buttons after the audio and remove emotion text
                this.setState({
                  emotion: '',
                  disabled: false
                });

                if (this.state.pairsToBuild < maxPairs) {
                  this.increaseDifficulty()
                } else {
                  this.resetDifficulty()
                }
                this.startGame()
              }
            }

          }, 1000)


          return this.setState({
            pairClicked: 0,
            singleClicked: 0,
          })

        }

      } else {

        // Track the first clicked pair
        this.setState({
          pairClicked: parseInt(id),
          singleClicked: floatId,
        })

        // Pulse the card      
        card.classList.remove('fadeIn')
        card.classList.add('pulse')

        // Rotate the card
        card.classList.add('memory-card--rotated')

      }

    }

  }

  // Used for increasing difficulty
  increaseDifficulty = () => {
    let currentDifficulty = this.state.pairsToBuild
    this.setState({ 
      width: 100 / (currentDifficulty + 1 ),
      pairsToBuild: currentDifficulty + 1 
    })
  }

  // Used for resetting difficulty
  resetDifficulty = () => {
    this.setState({ 
      width: 50,
      pairsToBuild: initialPairs 
    })
  }

  // Used for starting a new game
  startGame = async () => {

    // Clears cards
    this.setState({ cards: [] }, async () => {

      // Select the pairs to play
      await this.selectPairs()

      // Prepare a list of cards
      let cards = [];

      // Loop over pairs to play
      for (let key in this.state.pairsToPlay) {

        // Select the individual pair
        let pair = this.state.pairsToPlay[key]

        // push the component to the list of cards
        cards.push(<MemoryCard onClick={(e) => this.handleClick(e)} key={pair.id} pair={pair} cardWidth={this.state.width} />)

      }

      this.setState({ cards: cards })

    })

  }

  startInfo = (game) => {
      if(this.state.disabled == false) {
        // Stop explainer if already running
        this.stopExplainer();
        // show winokio head
        this.winokioExplainer(true);
          // Start info
          this.info = getInfo(game);
          this.info.play();
          // this.setState({disabled: true});
          this.info.onended = () => {
            // show winokio head
            this.winokioExplainer(false);
            // this.setState({disabled: false});
          }
      }
  }

  componentWillUnmount = () => {
    if(this.info) {
      this.info.pause();
    }
  }

  componentDidMount = async () => {

    // Start with explainer if not already listened\
    this.audioExplainer();

    // Scale game
    this.responsive()
    this.startGame()

    // Add resize event
    window.addEventListener('resize', () => this.responsive())

  }

  render() {

    return (
      <div className="memoryView">
        <div className="winokio-explainer">
            <img src="assets/img/islands/kapitein-hoofd.png" />
        </div>
        <div className="back-button" onClick={goBack}></div>
        <div className="info-button" onClick={() => this.startInfo('memory')}></div>

        <div 
          className="game" 
          // style={{ gridTemplate: `repeat(2, ${this.state.cardWidth}px)/repeat(${this.state.pairsToBuild}, ${this.state.cardWidth}px)` }}
          >
          {this.state.cards}
        </div>

        <div className="emotion-name">
          {this.state.emotion}
        </div>

        <div className="background"></div>

      </div>
    );
  }
}

export default MemoryView;
