import slugify from 'react-slugify';

export let songs = {
    "happy": {
        "name": "Blij",
        "image": "blij.jpg",
        "audio": "blij.mp3",
        slug() { return slugify(this.name) },
        "lyrics": `
            [0:04.50] 🎶
            [0:12.00] Ben je 😊
            [0:13.00] Ben je 😊
            [0:14.00] 👏 eens in je handen
            [0:15.50] 🎶
            [0:19.00] Ben je 😊
            [0:20.20] Ben je 😊
            [0:21.00] Toon je witte tanden
            [0:23.00] 🎶
            [0:27.00] Ben je 😊
            [0:27.50] Ben je 😊
            [0:28.50] Spring maar in het rond
            [0:30.50] Ben je 😊
            [0:31.50] Ben je 😊
            [0:32.00] Wiebel met je kont
            [0:34.00] 🎶
            [0:41.50] Ben je 😊
            [0:42.50] Ben je 😊
            [0:43.50] 👏 eens in je handen
            [0:45.00] 🎶
            [0:49.00] Ben je 😊
            [0:50.00] Ben je 😊
            [0:50.50] Toon je witte tanden
            [0:52.50] 🎶
            [0:56.50] Ben je 😊
            [0:57.00] Ben je 😊
            [0:58.00] Spring maar in het rond
            [1:00.00] Ben je 😊
            [1:01.00] Ben je 😊
            [1:01.50] Wiebel met je kont
            [1:03.50] 🎶
            [1:11.00] Ben je 😊
            [1:12.00] Ben je 😊
            [1:13.00] 👏 eens in je handen
            [1:15.00] 🎶
            [1:18.50] Ben je 😊
            [1:19.50] Ben je 😊
            [1:20.50] Toon je spierwitte tanden
            [1:22.50] 🎶
            [1:26.00] Ben je 😊
            [1:27.00] Ben je 😊
            [1:27.50] Spring maar in het rond
            [1:29.50] Ben je 😊
            [1:30.50] Ben je 😊
            [1:31.00] Wiebel met je
            [1:32.00] Wiebel met je
            [1:33.00] Wiebel met je kont
            [1:36.00]
        `
    },
    "beans": {
        "name": "Bonen",
        "image": "bonen.jpg",
        "audio": "bonen.mp3",
        slug() { return slugify(this.name) },
        "lyrics": `
            [0:00.00] 🎶
            [0:01.00] Hey, hey, hey
            [0:04.00] Hey, hey, hey
            [0:07.00] Hey, hey, hey
            [0:12.00] Witte bonen, zwarte bonen, groene bonen, Tom Boonen
            [0:18.00] 🔪bonen, sojabonen, sperziebonen, 👬bonen
            [0:23.50] Bruine bonen, tuinbonen, linzen en 👸bonen
            [0:29.50] Vleugelbonen, nierbonen, erwten en fluwelen bonen
            [0:35.00] Veldbonen, 🐎bonen, mollebonen, kidneybonen
            [0:37.50] Azukibonen, mungbonen, Louis Paul Bonen
            [0:40.50] Hey, hey, hey
            [0:43.00] Hey, hey, hey
            [0:46.00] Hey, hey, hey
            [0:52.00] Witte bonen, zwarte bonen, groene bonen, Tom Boonen
            [0:57.50] 🔪bonen, sojabonen, sperziebonen, 👬bonen
            [1:03.00] Bruine bonen, tuinbonen, linzen en 👸bonen
            [1:08.50] Vleugelbonen, nierbonen, erwten en fluwelen bonen
            [1:14.00] Veldbonen, 🐎bonen, mollebonen, kidneybonen
            [1:17.00] Azukibonen, mungbonen, Louis Paul Bonen
            [1:20.00] Hey, hey, hey
            [1:23.00] Hey, hey, hey
            [1:26.00] Hey, hey, hey
            [1:32.00] 
        `
    },
    "seaman": {
        "name": "Een echte zeeman",
        "image": "een-echte-zeeman.png",
        "audio": "zeeman.mp3",
        slug() { return slugify(this.name) },
        "lyrics": `
            [0:00.00] 🎶
            [0:11.50] Een echte zeeman ruikt naar 🌊
            [0:15.50] En zotte 🐟, en zotte 🐟
            [0:19.00] Een echte zeeman ruikt naar 🌊
            [0:23.50] En zotte 🐟, en zotte 🐟
            [0:27.00] Maar ook wel es een beetje naar 🍲
            [0:31.50] Ook wel es een beetje naar 💩
            [0:36.00] Maar het allermeeste naar de 🌊
            [0:38.00] Zingen jullie ‘t 🎶 met ons mee?
            [0:43.00] Een echte zeeman ruikt naar 🌊
            [0:47.00] En zotte 🐟, en zotte 🐟
            [0:50.00] Een echte zeeman ruikt naar 🌊
            [0:54.00] En zotte 🐟, en zotte 🐟
            [0:57.00] Maar ook wel es een beetje naar 🍲
            [1:02.00] Ook wel es een beetje naar 💩
            [1:06.00] Maar het allermeeste naar de 🌊
            [1:10.00] Zingen jullie ‘t 🎶 met ons mee?
            [1:13.00] La la la la la la
            [1:30.00] Ook wel es een beetje naar 🍲
            [1:34.00] Ook wel es een beetje naar 💩
            [1:38.00] Maar het allermeeste naar de 🌊
            [1:42.00] Zingen jullie ‘t 🎶 met ons mee?
        `
    },
    "wave_hands": {
        "name": "Handjes draaien",
        "image": "handjes-draaien.png",
        "audio": "handjes-draaien.mp3",
        slug() { return slugify(this.name) },
        "lyrics": `
        [0:00.00] 🎶
        [0:05.50] 🖐 draaien, 🍪bakke🥧
        [0:10.50] 🖐 draaien, 🍪bakke🐟
        [0:15.50] Je kunt het niet geloven hoe lekker dat dat is
        [0:21.00] Je kunt het niet geloven hoe lekker dat dat is
        [0:26.00] 🥢🐟
        [0:27.50] 🎶
        [0:32.00] 🖐 draaien, 🍪bakke🥧
        [0:37.00] 🖐 draaien, 🍪bakke🐟
        [0:42.00] Je kunt het niet geloven hoe lekker dat dat is
        [0:47.00] Je kunt het niet geloven hoe lekker dat dat is
        [0:52.00] 🥢🐟
        [0:54.00]
    `
    },
    "knee_toe": {
        "name": "Hoofd, schouders, knie en teen",
        "image": "hoofd-schouders-knie-en-teen.png",
        "audio": "knie-teen.mp3",
        slug() { return slugify(this.name) },
        "lyrics": `
            [0:00.00] 🎶
            [0:04.00] Hoofd, schouders, knie en teen, knie en teen
            [0:08.00] Hoofd, schouders, knie en teen, knie en teen
            [0:12.00] 👂, 👀, puntje van je 👃
            [0:16.00] Hoofd en schouders, knie en teen, knie en teen
            [0:20.00] 🎶
            [0:24.00] Hoofd, schouders, knie en teen, knie en teen
            [0:28.00] Hoofd, schouders, knie en teen, knie en teen
            [0:32.00] 👂, 👀, puntje van je 👃
            [0:36.00] Hoofd, schouders, knie en teen, knie en teen
            [0:40.00] 🎶
            [0:44.00] Hoofd, schouders, knie en teen, knie en teen
            [0:48.00] Hoofd, schouders, knie en teen, knie en teen
            [0:52.00] 👂, 👀, puntje van je 👃
            [0:56.00] Hoofd, schouders, knie en teen, knie en teen
            [1:00.00]
        `
    },
    "in_the_forest": {
        "name": "In het bos",
        "image": "in-het-bos-daar-staat-een-huisje.png",
        "audio": "in-het-bos.mp3",
        slug() { return slugify(this.name) },
        "lyrics": `
            [0:00.00] 🎶
            [0:16.00] In het 🌲, daar staat een 🏠
            [0:20.00] ‘k keek eens door het vensterraam 
            [0:24.00] Kwam een 🐇 aangelopen
            [0:28.00] klopte aan de 🚪
            [0:32.00] Help mij, help mij uit de nood
            [0:36.00] of de jager 🔫 mij dood 
            [0:40.00] Laat mij in uw 🏠 klein
            [0:44.00] ‘k zal u dankbaar zijn
            [0:47.00] 🎶
            [0:54.00] In het 🌲, daar staat een 🏠
            [0:57.50] ‘k keek eens door het vensterraam
            [1:01.50] Kwam een 🐇 aangelopen
            [1:05.50] klopte aan de 🚪
            [1:09.50] Help mij, help mij uit de nood
            [1:13.00] of de jager 🔫 mij dood
            [1:17.50] Laat mij in uw 🏠 klein
            [1:21.00] ‘k zal u dankbaar zijn
            [1:26.00]
        `
    },
    "bully": {
        "name": "Pesten",
        "image": "pesten.jpg",
        "audio": "pesten.mp3",
        slug() { return slugify(this.name) },
        "lyrics": `
            [0:02.50] 🎶
            [0:07.50] Moet je weer eens pesten? Bullebak!
            [0:10.50] Ga je weer eens plagen? Bullebak!
            [0:13.00] Sta je weer te sarren? Bullebak!
            [0:16.50] Bullebak! Bullebak!
            [0:18.50] Stop met pesten, dat is niet fijn
            [0:21.00] Het 💕 van de bullebak … is klein!
            [0:23.50] 🎶
            [0:29.00] Pesten is voor losers, Bullebak!
            [0:31.50] Eigenlijk ben je 😢, Bullebak!
            [0:34.00] En helemaal niet moedig, Bullebak!
            [0:38.00] Bullebak! Bullebak!
            [0:39.50] Stop met pesten, dat is niet fijn
            [0:42.00] Het 💕 van de bullebak … is klein!
            [0:45.00] 🎶
            [0:46.50] Bullebak!
            [0:47.50] 🎶
            [0:49.50] Bullebak!
            [0:50.20] 🎶
            [0:52.00] Bullebak!
            [0:53.00] 🎶
            [0:54.00] Bullebak! Bullebak!
            [0:55.50] Stop met pesten, dat is niet fijn
            [0:58.00] De ⚽ van de bullebak … zijn klein!
            [1:01.00]
        `
    },
    "red_cabbage": {
        "name": "Rode kool",
        "image": "rode-kool.jpg",
        "audio": "rode-kool.mp3",
        slug() { return slugify(this.name) },
        "lyrics": `
            [0:00.00] 🎶
            [0:02.00] Kool, kool, kool!
            [0:03.50] We eten rodekool
            [0:05.50] Met worsten en puree
            [0:07.00] Alle 🐭 eten mee
            [0:09.50] Kool, kool, kool!
            [0:11.50] We eten rodekool
            [0:13.50] Eén kilo of twee
            [0:15.00] Vol vitamine C
            [0:17.50] Kool, kool, kool!
            [0:19.50] We eten rodekool
            [0:21.50] Kool, kool, kool!
            [0:23.50] Een rodekool-diner!
            [0:27.50] Kool, kool, kool!
            [0:29.50] We eten rodekool
            [0:31.50] Met worsten en puree
            [0:33.00] Alle 🐭 eten mee
            [0:35.50] Kool, kool, kool!
            [0:37.50] We eten rodekool
            [0:39.50] Eén kilo of twee
            [0:41.00] Vol vitamine C
            [0:43.50] Kool, kool, kool!
            [0:45.50] We eten rodekool
            [0:47.50] Kool, kool, kool!
            [0:49.50] Een rodekool-diner!
            [0:54.00] 🎶
            [1:09.50] Kool, kool, kool!
            [1:11.00] We eten rodekool
            [1:13.00] Kool, kool, kool! 
            [1:15.00] Een rodekool-diner!
            [1:20.00]
        `
    },
    "tomato": {
        "name": "Tomati tomata tomatuti",
        "image": "tomati.jpg",
        "audio": "tomati.mp3",
        slug() { return slugify(this.name) },
        "lyrics": `
            [0:00.00] 🎶
            [0:08.00] Tomati 🍅 tomatuti
            [0:10.00] Tomatuti courgetti pataat
            [0:12.50] Tomati 🍅 tomatuti
            [0:14.50] Tomatuti courgetti met sla
            [0:16.50] Tomati 🍅 tomatuti
            [0:18.50] Tomatuti courgetti pataat
            [0:20.50] Tomati 🍅 tomatuti
            [0:22.50] Tomatuti courgetti met sla
            [0:25.50] Paprika, paprika
            [0:27.20] 🥒 & 🥦
            [0:29.50] Paprika, paprika
            [0:31.50] Prei en koolrabi
            [0:33.50] 🎶
            [0:41.50] Tomati 🍅 tomatuti
            [0:43.50] Tomatuti courgetti pataat
            [0:45.50] Tomati 🍅 tomatuti
            [0:47.50] Tomatuti courgetti met sla
            [0:50.00] Tomati 🍅 tomatuti
            [0:52.00] Tomatuti courgetti pataat
            [0:54.00] Tomati 🍅 tomatuti
            [0:56.00] Tomatuti courgetti met sla
            [0:58.50] Paprika, paprika 
            [1:00.50] 🥒 & 🥦
            [1:02.50] Paprika, paprika
            [1:04.50] Prei en koolrabi 
            [1:07.00]
        `
    },
    "two_hands": {
        "name": "Twee handjes op de tafel",
        "image": "twee-handjes-op-de-tafel.png",
        "audio": "twee-handjes.mp3",
        slug() { return slugify(this.name) },
        "lyrics": `
            [0:00.00] 🎶
            [0:02.50] Twee 🖐 op de tafel 
            [0:05.00] twee 🖐 in de zij
            [0:08.00] twee 🖐 op de schouders
            [0:11.00] op ‘t 🧑 allebei
            [0:13.50] Nu maken we twee 👊
            [0:16.50] zo stevig als ‘t maar kan
            [0:19.50] Daar gaan we nu mee 🥁
            [0:22.50] van je rommel de bommel de 💣
            [0:25.50] De 👍 zijn de dikste 
            [0:28.50] de pinkjes zijn maar klein
            [0:31.00] Nu moeten alle 🖐
            [0:34.00] weer vlug op ‘t rugje zijn.
            [0:37.00]
        `
    }
}