import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class SongCard extends Component {
    render() {
        return (
            <Link to={`/singalong/${this.props.song.slug()}`} onClick={this.props.update}>
                {this.props.song.name}
            </Link>
        );
    }
}