let data = [
    // winter
    [
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/ziek-zijn.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/vrieskou.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/aftellen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/cadeautjes.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/carnaval.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/de-wolf-heeft-honger.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/donker.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/driekongingen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/het-heeft-gesneeuwd.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/jarig-in-de-winter.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/kaarsjes.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/koekjes-bakken.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/koude-handen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/nieuwjaarslied.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/nieuwjaarszingen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/o-dennenboom.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/op-de-slee.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/pinguïn-vers.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/schaatsen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/sneeuwvlokje.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/valentijn.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/versieren.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/vrieskou.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/vuurwerk.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/warme-soep.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/warme-wol.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'winter',
            image: './assets/img/seasons/winter-cover.jpg',
            audio: './assets/sounds/seasons/winter/ziek-zijn.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/winter.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        }
    ],
    // zomer
    [
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/de-bij.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/het-zonnelied.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/ik-kan-zwemmen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/ijsjes.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/warm-warm-warm.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/vakantie.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/op-reis-naar-parijs.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/onweer.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/jarig-in-de-zomer.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/kleine-zebra.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/ik-bouw-een zandkasteel.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/de-waterslang.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/limonade.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/tomati-tomata-tomatuti.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/rode-bessen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/regenboog.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/barbecue.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/het-is-langer-licht.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/muggen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/hangmat.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/terug-naar-school.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/de-chinese-vlieger.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/ik-kan-fietsen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/vallende-ster.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'summer',
            image: './assets/img/seasons/summer-cover.jpg',
            audio: './assets/sounds/seasons/summer/het-bos.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/zomer.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
    ],
    // herfst
    [
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/een-dikke-spin.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/noten.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/als-wolken-komen-rusten.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/dansen-tussen-bladeren.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/de-boekentassenblues.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/dennenappel.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/de-pantoffelhond.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/een-dikke-spin.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/egel.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/er-zit-een-wortel-in-mijn-schoen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/fazant-met-hoedje.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/fons-de-oude-schildpad.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/halloween.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/hey-eekhoorn.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/ik-ben-een-mandarijn.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/ik-blijf-wakker.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/jarig-in-de-herfst.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/knip-knip.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/melancholie.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/mevrouw-de-uil.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/noten.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/paddenstoelen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/pompoenen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/rodekool.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/salut-mijn-liefste-zon.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/springen-in-de-plassen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'autumn',
            image: './assets/img/seasons/autumn-cover.jpg',
            audio: './assets/sounds/seasons/autumn/voorlezen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/herfst.wav',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        }
    ],
    // lente
    [
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/lentepoets.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/de-rups.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/aspergesoep.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/bomen-klimmen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/de-japanse-kerselaar.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/de-rups.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/de-tuiner.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/de-vlinder.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/groeien.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/haasje-van-chocola.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/het-grote-nieslied.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/het-mamalied.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/het-papalied.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/ik-ken-een-kever.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/in-mei-leggen-alle-vogels-een-ei.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/jarig-in-de-lente.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/korte-broeken-korte-jurken.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/lentepoets.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/lief-klein-lammetje.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/madeliefje.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/meiklokjes.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/op-1-april.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/op-de-boerderij.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/paardenbloemen.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/picknick.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/pieter-de-gieter.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        },
        {
            season: 'spring',
            image: './assets/img/seasons/spring-cover.jpg',
            audio: './assets/sounds/seasons/spring/pimpampoentje.mp3',
            correctSound: [
                './assets/sounds/correct-sounds/seasons/goed-gekozen-korter.mp3',
                './assets/sounds/correct-sounds/seasons/goed-gekozen.wav',
                './assets/sounds/correct-sounds/seasons/lente.wav',
                './assets/sounds/correct-sounds/seasons/lente-kort.mp3',
                './assets/sounds/correct-sounds/juist_01.mp3',
                './assets/sounds/correct-sounds/juist_02.mp3',
                './assets/sounds/correct-sounds/juist_03.mp3',
                './assets/sounds/correct-sounds/juist_04.mp3',
                './assets/sounds/correct-sounds/juist_05.mp3',
                './assets/sounds/correct-sounds/juist_06.mp3',
                './assets/sounds/correct-sounds/juist_07.mp3',
                './assets/sounds/correct-sounds/juist_08.mp3',
                './assets/sounds/correct-sounds/juist_09.mp3',
                './assets/sounds/correct-sounds/juist_10.mp3',
            ],
            failSound: [
                './assets/sounds/fail-sounds/fout_01.mp3',
                './assets/sounds/fail-sounds/fout_02.mp3',
                './assets/sounds/fail-sounds/fout_03.mp3',
                './assets/sounds/fail-sounds/fout_04.mp3',
                './assets/sounds/fail-sounds/fout_05.mp3',
                './assets/sounds/fail-sounds/fout_06.mp3',
                './assets/sounds/fail-sounds/fout_07.mp3',
                './assets/sounds/fail-sounds/fout_08.mp3',
            ]
        }
    ]
]

export default data;