import React, { Component } from 'react'
import { songs } from './variables/songs'
import RabbitLyrics from "rabbit-lyrics"
import { goBack } from '../../scripts/helpers'
import './styles.scss'

class SingalongView extends Component {
    constructor(props) {
        super(props)

        let song = this.props.match.params.song

        // Loop over songs
        Object.keys(songs).forEach(key => {
            let value = songs[key];
            // Check if this is the chosen song
            if(song === value.slug()) {
                this.state = { song: songs[key] }
            }
        });
        
        // Build necessary refs
        this.dotRef = React.createRef();
        this.audioRef = React.createRef();
        this.lyricsRef = React.createRef();

    }

    componentDidMount() {
        if(this.state.song) {
            // this.animateDot()
        }

        new RabbitLyrics({
            element: this.refs.lyricsRef,
            mediaElement: this.refs.audioRef
        });

        this.refs.audioRef.onended = () => {
            goBack();
        }
    }

    animateDot() {
        var count = 0;

        var audio = document.querySelector("#audio-1");
        // Audio begins playing
        audio.addEventListener("play", function() {
            var first = document.querySelector(".rabbit-lyrics__line");
            // Get the start point of the first lyrics line
            count = parseFloat(first.dataset.start, 0) - .5;
            // console.log(count);
            checkForChanges();
        });

        function highlightText() {
            // Get the active line
            var active = document.querySelector(".rabbit-lyrics__line--active");
            var width = active.offsetWidth;
            var start = active.dataset.start;
            var end = active.dataset.end;
            // Calculate duration of animation
            var duration = end - start;
            // console.log(duration, width);

            var elem = document.getElementById("dot"); 
            var pos = 0;
            var axis = 5;
            var id = setInterval(frame, duration);

            function frame() {
                // If dot reached the end
                if (pos === width) {
                    clearInterval(id);
                } else {
                pos++; 
                // Bounce the dot
                if(axis > 0 && pos % 5){
                    axis -= 10;
                } else {
                    axis += 10;
                }
                // Update the position
                    elem.style.transform = `translate(${pos}px, ${axis}px)`;
                }
            }
        }

        function checkForChanges() {
            var lines = document.querySelectorAll(".rabbit-lyrics__line");
            // Loop over lyric lines
            for(var i = 0; i < lines.length; i++){
                // Is one active line active
                if (lines[i].classList.contains("rabbit-lyrics__line--active")) {
                    var active = document.querySelector(".rabbit-lyrics__line--active");
                    var start = active.dataset.start;

                    count += .5;

                    // console.log(start, count);
                    if (start === count) {
                        // console.log("yaaa");
                        highlightText();
                    }
                }
            }
            setTimeout(checkForChanges, 500);
        }

        console.log(audio);
    }

    render() {
        return (
            <div className="singalongView">
                <div className="back-button" onClick={goBack}></div>
                <h1>{this.state.song.name}</h1>
                <audio id="audio-1"  ref="audioRef" autoPlay>
                    <source src={`/assets/sounds/singalong/${this.state.song.audio}`} type="audio/mpeg" />
                </audio>

                <img className="singalong-img" src={`/assets/img/singalong/${this.state.song.image}`} alt="Liedjescover"></img>

                <div className="rabbit-lyrics" id="lyrics-1" ref="lyricsRef">
                    {this.state.song.lyrics}
                </div>
                {/* <div id="dot"></div> */}
                <div className="background background--yellow"></div>
            </div>
        );  
    }
}

export default SingalongView;