import React, { Component } from 'react';
import seasons from './data';
import { goBack, randomNumber, getInfo } from '../../scripts/helpers'

class SeasonsView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            season: "",
            song: "",
            songLink: "",
            failSounds: "",
            correctSounds: "",
            activeSeason: [],
            disabled: false,

            cardWidth: 25,
        };
        // Static variables
        this.seasons = ['winter', 'spring', 'summer', 'autumn'];
        this.info = null;

        // Create refs for retrieving DOM elements
        this.song = React.createRef();
        this.winter = React.createRef();
        this.spring = React.createRef();
        this.summer = React.createRef();
        this.autumn = React.createRef();
    }

    audioExplainer = () => {
        if(localStorage.getItem('seasons-explainer') == null) {
            localStorage.setItem('seasons-explainer', true);
            this.info = getInfo('seasons');
            setTimeout(() => {
                this.info.play();
                // show winokio head
                this.winokioExplainer(true);
            }, 1000);
            this.info.onended = () => {
                // remove winokio head
                this.info = null;
                this.winokioExplainer(false);
                this.refs.song.play()
            }
        }
    }

    winokioExplainer = (active) => {
        let winokio = document.querySelector('.winokio-explainer');
        if(active) {
            winokio.classList.add('winokio-explainer--active');
        } else {
            winokio.classList.remove('winokio-explainer--active')
        }
    }

    stopExplainer = () => {
        if(this.info) {
            // remove winokio head
            this.winokioExplainer(false);
            this.info.pause();
            this.info.currentTime = 0;
            this.info = null;
        }
    }

    componentDidMount = async () => {
        // Start with explainer if not already listened\
        this.audioExplainer();
        this.startGame();
    }

    startGame = async() => {
        await this.pickSeason()
        await this.pickSeasonNumber()
        this.setSound()

        // Animate choises
        this.seasons.forEach(s => {
            this.refs[s].classList.add('animated', 'zoomIn');
        });
        // Remove animated classes adter 1 sec
        setTimeout(() => {
            this.seasons.forEach(s => {
                this.refs[s].classList.remove('animated', 'zoomIn');
            });
        }, 1000);

    }

    pickSeason = async () => {
        // Pick a random season
        return this.setState({ season: randomNumber(4) })
    }

    pickSeasonNumber = async () => {     
        // Get a random song from the season
        let song = randomNumber(seasons[this.state.season].length)
        // Save the song to the state
        return this.setState({ song: seasons[this.state.season][song] })
    }

    resetSound = async () => {
        // Stop music playing
        this.refs.song.currentTime = 0
    }

    setSound = async () => {
        this.resetSound()
        // Update the season & song state
        this.setState({
          chosenSeason: this.state.song.season,
          failSounds: this.state.song.failSound,
          correctSounds: this.state.song.correctSound,
          disabled: false
        }, () => {
            // play sound if no explainer 
            if(this.info == null) {
                // Stop explainer if already running
                this.stopExplainer();
                // Load and initialize the chosen song
                this.refs.song.load()
                // set volume on max
                this.refs.song.volume = 1;
                this.refs.song.play()
            }
        })
    }

    playSound = () => {
        // Stop explainer if already running
        this.stopExplainer();
        // set volume on max
        this.refs.song.volume = 1;
        this.refs.song.play();
    }

    checkResult = (season) => {
        this.playSound();
        // Disable the state
        this.setState({disabled: true})
        // Check if the user guessed correctly
        if(season === this.state.chosenSeason) {
            // Reduce volume on correct
            this.refs.song.volume = 0.2;
            // Animate the other choises out of the view
            this.seasons.forEach(s => {
                if(s != season) {
                    this.refs[s].classList.add('animated', 'zoomOut');
                }
            });
            // Animate the right choise
            this.refs[season].classList.add('animated', 'rubberBand');
            let random = randomNumber(this.state.correctSounds.length);
            let correct = new Audio(this.state.correctSounds[random]);
            correct.play();
            // On correct sound ended
            correct.onended = () => {
                // Pause the main song
                this.refs.song.pause();
                // Remove animated classes
                this.seasons.forEach(s => {
                    this.refs[s].classList.remove('animated', 'zoomOut', 'rubberBand');
                });
                // Restart the game
                this.startGame();
            }
        } else {
            // Reduce volume on fail
            this.refs.song.volume = 0.2
            // Add animation
            this.refs[season].classList.add('animated', 'shake');
            let random = randomNumber(this.state.failSounds.length);
            let fail = new Audio(this.state.failSounds[random]);
            fail.play();
            // On fail sound ended
            fail.onended = () => {
                // Make the buttons again clickable
                this.setState({disabled: false});
                // remove animation
                this.refs[season].classList.remove('animated', 'shake');
                // Set volume back on 100% after fail ended
                this.refs.song.volume = 1
            }
        }
    }

    startInfo = (game) => {
        if(this.state.disabled == false) {
            // Stop explainer if already running
            this.stopExplainer();
            // show winokio head
            this.winokioExplainer(true);
            // Reduce volume of the song
            this.refs.song.volume = 0.1;
            // Start info
            this.info = getInfo(game);
            this.info.play();
            // this.setState({disabled: true});
            this.info.onended = () => {
                this.info = null;
                // show winokio head
                this.winokioExplainer(false);
                // Set volume of the song back to 100%
                this.refs.song.volume = 1;
                // this.setState({disabled: false});
            }
        }
    }

    componentWillUnmount = () => {
        if(this.info) {
          this.info.pause();
        }
    }

    render() {
        return (
            <div>
                <div className="seasons-game">
                    <div className="winokio-explainer">
                        <img src="assets/img/islands/kapitein-hoofd.png" />
                    </div>
                    <div className="back-button" onClick={goBack}></div>
                    <div className="info-button" onClick={() => this.startInfo('seasons')}></div>
                    <div className="game-container seasons-container">

                        <button 
                            ref="winter"
                            disabled={this.state.disabled}
                            className="seasons-card" 
                            style={{backgroundImage: "url(./assets/img/seasons/winter-cover.jpg)", width: this.state.cardWidth + "%"}}
                            onClick={() => this.checkResult('winter')}
                        ></button>
                        
                        <button 
                            ref="spring"
                            disabled={this.state.disabled}
                            className="seasons-card" 
                            style={{backgroundImage: "url(./assets/img/seasons/spring-cover.jpg)", width: this.state.cardWidth + "%"}}
                            onClick={() => this.checkResult('spring')}
                        ></button>
                        
                        <button  
                            ref="summer"
                            disabled={this.state.disabled}
                            className="seasons-card" 
                            style={{backgroundImage: "url(./assets/img/seasons/summer-cover.jpg)", width: this.state.cardWidth + "%"}}
                            onClick={() => this.checkResult('summer')}
                            ></button>

                        <button 
                            ref="autumn"
                            disabled={this.state.disabled}
                            className="seasons-card" 
                            style={{backgroundImage: "url(./assets/img/seasons/autumn-cover.jpg)", width: this.state.cardWidth + "%"}}
                            onClick={() => this.checkResult('autumn')}
                        ></button>
                    </div>
                    {/* Game Container */}
                    <div className="background background--red"></div>
                    {/* Background */}
                </div>
                {/* Instrument Game */}
                <audio ref="song">
                    <source src={this.state.song.audio} type="audio/mpeg" />
                </audio>
                <div className="main-btn-container">
                    <button disabled={this.state.disabled} className="main-btn audio-btn" href="#" onClick={this.playSound}>
                        <img src="/assets/img/audio.svg" alt="Audio" />
                    </button>
                </div>
            </div>
        );
    }
}

export default SeasonsView;

