import React, { Component } from 'react';
import { goBack, getInfo } from '../../scripts/helpers'

// data
import veggies from './data';
let difficulty = 4;

class VeggiesView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            word: "",
            song: "",
            failSounds: "",
            difficulty: difficulty,
            level: 0,
            veggies: veggies,
            activeVeggie: {},
            veggiesToShow: [],
            disabled: false,

            cardWidth: 25,
        };

        // Create refs for map elements
        this.cards = []
        this.info = null

    }

    audioExplainer = () => {
        if(localStorage.getItem('veggies-explainer') == null) {
            localStorage.setItem('veggies-explainer', true);
            this.info = getInfo('veggies');
            setTimeout(() => {
                this.info.play();
                // show winokio head
                this.winokioExplainer(true);
            }, 1000);
            this.info.onended = () => {
                // remove winokio head
                this.winokioExplainer(false);
                this.playWord();
            }
        }
    }

    winokioExplainer = (active) => {
        let winokio = document.querySelector('.winokio-explainer');
        if(active) {
            winokio.classList.add('winokio-explainer--active');
        } else {
            winokio.classList.remove('winokio-explainer--active')
        }
    }

    stopExplainer = () => {
        if(this.info) {
            // remove winokio head
            this.winokioExplainer(false);
            this.info.pause();
            this.info.currentTime = 0;
        }
    }

    shuffle = (a) => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }
 
    randomNumber = (max) => {
        let randomNumber = Math.floor(Math.random() * max) + 1;
        return randomNumber - 1;
    }

    loadWord = (veggie) => {
        this.setState({word: new Audio(veggie.word)})
    }

    playWord = () => {
        // Stop explainer if already running
        this.stopExplainer();
        this.state.word.play();
    }

    checkVeggie = (clickedVeggie) => {
        this.setState({song: new Audio(clickedVeggie.song)})
    }

    loadAsync = () => {
        let self = this;
        this.state.song.onended = () => {
            // Activate button on song ended
            this.setState({disabled: false});
            // Increase Level and difficulty
            self.increaseLevel();
            // Restart game
            self.startGame();
            // Remove animated classes after 1 sec
            let keys = Object.keys(this.cards)
            keys.forEach(key => {
                if(this.cards[key] !== null) {
                    // Aniamte the right choise
                    this.cards[key].classList.remove('animated', 'rubberBand', 'zoomOut');
                }
            });
        }
    }

    checkResult = async (clickedVeggie) => {
        // Stop explainer if already running
        this.stopExplainer();
        // Disable buttons on result check
        this.setState({disabled: true});
        // If the right choise
        if (clickedVeggie.word === this.state.activeVeggie.word) {
            await this.checkVeggie(clickedVeggie);
            await this.state.song.play();
            await this.loadAsync();
            // Animate the other choises out of the view
            let keys = Object.keys(this.cards)
            keys.forEach(key => {
                if(this.cards[key] !== null) {
                    // Aniamte out the wrong choises
                    if(key !== clickedVeggie.name) {
                        this.cards[key].classList.add('animated', 'zoomOut');
                    // Aniamte the right choise
                    } else {
                        this.cards[key].classList.add('animated', 'rubberBand');
                    }
                }
            });
        // if the wrong choise
        } else {
            // Animate the wrong choosen veggie
            this.cards[clickedVeggie.name].classList.add('animated', 'shake');
            let random = this.randomNumber(this.state.failSounds.length);
            let fail = new Audio(this.state.failSounds[random]);
            fail.play();
            fail.onended = () => {
                this.cards[clickedVeggie.name].classList.remove('animated', 'shake');
                this.setState({disabled: false})
            }
        }
    }
   
    
    increaseDifficulty = () => {
        this.setState({difficulty: this.state.difficulty + 2})
    }

    increaseLevel = () => {
        if(this.state.level < 10) {
            this.setState({level: this.state.level + 1})
            if((this.state.level % 3) == 0) {
                this.increaseDifficulty();
            }
            if(this.state.difficulty === 10) {
                this.setState({cardWidth: 20})
            }
        }
    }

    startGame = () => {
        let randomVeggies = this.shuffle(this.state.veggies);
        let veggiesToShow = [];
        // Create as many cards as required (difficulty)
        for (let i = 0; i < this.state.difficulty; i++) {
            veggiesToShow.push(randomVeggies[i]);
        }
        let activeVeggie = veggiesToShow[this.randomNumber(this.state.difficulty)];
        this.setState({ 
            veggiesToShow: veggiesToShow,
            activeVeggie: activeVeggie,
            failSounds: activeVeggie.failSound
        })   
        // Load new sound
        this.loadWord(activeVeggie);
        // Fake timeout to create animation on game start
        setTimeout(() => {
            // Animate in view of the cards
            let keys = Object.keys(this.cards)
            keys.forEach(key => {
                if(this.cards[key] !== null) {
                    this.cards[key].classList.remove('hidden');
                    this.cards[key].classList.add('animated', 'zoomIn');
                }
            });
        }, 50);
        // Remove animated classes after 1 sec
        setTimeout(() => {
            // Animate out the cards
            let keys = Object.keys(this.cards)
            keys.forEach(key => {
                if(this.cards[key] !== null) {
                    this.cards[key].classList.remove('animated', 'zoomIn');
                }
            });
            // play sound if no explainer 
            if(!this.info) {
                this.playWord();
            }
        }, 1000);
    }

    startInfo = (game) => {
        if(this.state.disabled == false) {
            // Stop explainer if already running
            this.stopExplainer();
            // show winokio head
            this.winokioExplainer(true);
            this.info = getInfo(game);
            this.info.play();
            // this.setState({disabled: true});
            this.info.onended = () => {
                // show winokio head
                this.winokioExplainer(false);
                // this.setState({disabled: false});
            }
        }
    }

    componentDidMount() { 
        // Start with explainer if not already listened\
        this.audioExplainer();
        this.startGame();
    }

    componentWillUnmount = () => {
        if(this.info) {
          this.info.pause();
        }
    }

    render() {
        const veggiesList = this.state.veggiesToShow.map((veggie, i) => 
            <button 
                key={veggie.name}
                ref={(ref) => { this.cards[veggie.name] = ref; return true; }}
                disabled={this.state.disabled}
                className="veggie-card hidden"
                style={{ width: this.state.cardWidth + "%" }} 
                onClick={() => this.checkResult(veggie)}>
                <img src={veggie.image} alt="Afbeelding groente" />
            </button>
        ); 
        return (
            <div>
                <div className="veggie-game">
                    <div className="back-button" onClick={goBack}></div>
                    <div className="winokio-explainer">
                        <img src="assets/img/islands/kapitein-hoofd.png" />
                    </div>
                    <div className="info-button" onClick={() => this.startInfo('veggies')}></div>
                    <div className="game-container">
                        {veggiesList}
                    </div>
                    <div className="background background--orange"></div>
                </div>
                <div className="main-btn-container">
                    <a className="main-btn audio-btn" href="#" onClick={() => this.playWord()}>
                        <img src="/assets/img/audio.svg" alt="Audio" />
                    </a>
                </div>
            </div>
        );
    }
}

export default VeggiesView;
